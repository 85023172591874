#chat-window {
  width: 100%;
  height: 100%;
 
}

#chat-windows-messages {
  height: 38rem;
  overflow: auto;
}


.chat-message-item {
  display: flex;
  justify-content: flex-start;
}

.chat-message-item-my {
  display: flex;
  justify-content: flex-end;
}

.chat-message {
  border: 1px solid rgba(0, 0, 0, 0.043);
  padding: 15px;
  border-radius: 5px;
  max-width: 40%;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.044);

}

.chat-message-my {
  display: block;
  text-align: end;
  background-color: #5664d2;
  color: white;
}

.chat-messages-error {
color: red;
position: relative;
  top: 25px;
  left: 8%;
}

@media (max-width: 1600px) {
.chat-message {
  max-width: 45%
}
}
@media (max-width: 1400px) {
.chat-message {
  max-width: 55%
}
}
@media (max-width: 1200px) {
.chat-message {
  max-width: 65%
}
}
@media (max-width: 1040px) {
.chat-message {
  max-width: 70%
}
}
@media (max-width: 767px) {
.chat-message {
  max-width: 80%
}
}